




















import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import { VisualizationPanel } from "survey-analytics";
import { SurveyModel, Question } from "survey-vue";

import pollsVuexModule from "@/store/vuex-modules/polls";
import { PollResponse } from "@/types/interfaces";
const pollsStore = getModule(pollsVuexModule);

@Component({
    components: {
        PageTitleHeader
    }
})
export default class PollResultsView extends Vue {
    resultsPanel?: VisualizationPanel;
    activePoll = "0";
    responsesInterval?: number;

    @Watch("activePoll")
    async updatePollOnTabChange() {
        await this.initializeResults();
    }

    get sessionId() {
        return this.$route.params.id;
    }

    get polls() {
        return pollsStore.polls;
    }

    get responses() {
        return pollsStore.singlePollResponses;
    }

    async mounted() {
        await this.initializePolls();
        await this.initializeResults();
    }

    async initializePolls() {
        await pollsStore.getPollsForSession(this.sessionId);
    }

    async initializeResults() {
        this.resultsPanel = undefined;
        const poll = this.polls[parseInt(this.activePoll)];
        const pollId = poll.pollId;

        this.clearResponsesInterval();

        await pollsStore.getSinglePollResponses({
            sessionId: this.sessionId,
            pollId: pollId
        });

        const analyticsOptions = {
            haveCommercialLicense: true,
            allowDynamicLayout: false,
            allowHideQuestions: false
        };

        const survey = new SurveyModel(poll);
        const questions = survey.getAllQuestions();
        const thisPollResponses = this.responses.map(
            (resp: PollResponse) => resp.answers
        );

        // HTML before questions can't be vizualized. This reduces results to just the question, so may be problematic?
        const filteredQuestions = questions.filter((question) => {
            if (!("html" in question)) {
                return question;
            }
        });

        const dataTables = new VisualizationPanel(
            filteredQuestions,
            thisPollResponses,
            analyticsOptions
        );

        // filteredQuestions.forEach((question: Question) => {
        //     const visualizer = dataTables.getVisualizer(question.name);
        //     const validTypes = ["bar", "pie", "doughnut", "scatter"];
        //     const chartType =
        //         poll.chartType && validTypes.includes(poll.chartType)
        //             ? poll.chartType
        //             : "pie";
        //     /**
        //      * TODO: Fix explicit any. For some reason the VisualizerBase type this variable is set as throws
        //      * a property does not exist error, despite the fact that this works fine.
        //      */
        //     (visualizer as any).setChartType(chartType);
        // });

        this.resultsPanel = dataTables;

        const container = document.getElementById("pollResultsPanel");

        if (container) {
            container.innerHTML = "";
            this.resultsPanel.render(container);
        }

        this.responsesInterval = window.setInterval(
            this.initializeResults,
            10000
        );
    }

    clearResponsesInterval() {
        window.clearInterval(this.responsesInterval);
    }
}
